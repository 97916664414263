<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body>
              <b-row>
                <b-col
                  cols="3"
                  lg="3"
                >
                  <div class="mt-2">
                    <b-form-group
                      label="رقم الطلب"
                      label-for="payment-method"
                    >
                      {{ Form.code }}
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  lg="3"
                >
                  <div class="mt-2">
                    <b-form-group
                      label="الموظف"
                      label-for="payment-method"
                    >
                      {{ Form.created_by }}
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  v-if="Form.department"
                  cols="3"
                  lg="3"
                >
                  <div
                    v-if="Form.department.name"
                    class="mt-2"
                  >
                    <b-form-group
                      label="قسم الموظف"
                      label-for="payment-method"
                    >
                      {{ Form.department.name }}
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  md="4"
                  xl="3"
                  class="invoice-actions"
                >
                  <!-- Payment Method -->
                  <div class="mt-2">
                    <b-form-group
                      label="تاريخ الطلب"
                      label-for="payment-method"
                    >
                      {{ Form.date }}
                    </b-form-group>

                    <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                  </div>
                </b-col>
                <b-col
                  cols="3"
                  md="4"
                  xl="3"
                  class="invoice-actions"
                >
                  <!-- Payment Method -->
                  <div class="mt-2">
                    <b-form-group
                      label="الملاحظات "
                      label-for="payment-method"
                    >
                      {{ Form.notes }}
                    </b-form-group>

                    <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                  </div>
                </b-col>

                <b-col
                  cols="3"
                  md="4"
                  xl="3"
                  class="invoice-actions"
                >
                  <!-- Payment Method -->
                  <div class="mt-2">
                    <b-form-group
                      label="التفاصيل "
                      label-for="payment-method"
                    >
                      {{ Form.details }}
                    </b-form-group>

                    <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                  </div>
                </b-col>
                <b-col cols="12">
                  <label
                    style="font-size:15px"
                    class="mb-10 m-10"
                  > المواد المطلوبة</label>
                  <b-row
                    v-for="(item, index) in Form.order_items"
                    :key="index"
                  >

                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="3"
                      lg="3"
                    >
                      <label>المادة</label>
                      <v-select
                        v-model="item.item_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ItemData"
                        disabled
                        label="name"
                        :clearable="false"
                        :reduce="(val) => val.id"
                        class="mb-2 item-selector-title"
                        placeholder="Select Item"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                    >
                      <label>الكمية</label>
                      <b-form-input
                        v-model="item.quantity"
                        type="number"
                        class="mb-2"
                        disabled
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-group
                        label-for="notes"
                      >
                        <label>الواحدة</label>
                        <v-select
                          v-model="item.unit_id"
                          :dir="
                            $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                          "
                          disabled
                          :options="ubitsOption"
                          label="en_name"
                          :clearable="false"
                          :reduce="(val) => val.id"
                          class="mb-2 item-selector-title"
                          placeholder="الواحدة "
                        />

                      </b-form-group>

                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-group
                        label-for="notes"
                      >
                        <label>وقت التسليم</label>
                        {{ item.recive_date }}

                      </b-form-group>

                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-group
                        label-for="notes"
                      >
                        <label>مكان التسليم</label>
                        {{ item.recive_location }}

                      </b-form-group>

                    </b-col>
                    <!--
                        <feather-icon
                          icon="CheckIcon"
                          variant="outline-danger"
                          class="mr-50 mb-10"
                        />
                       <br></br> -->
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->

            <!-- Items Section -->
            <b-card-body v-if="role.some( el => el['name'] === 'supply_officer' ) ">
              <b-row>
                <b-col
                  cols="4"
                  lg="5"
                  class="m-10"
                >
                  <label> لمعرفة المواد المتوفرة اختر مستودع</label>
                  <v-select
                    v-model="warehouse_id"
                    :options="warehouseOption"

                    label="name"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    class="mb-2 item-selector-title"
                    @input="getAvialableItem"
                  />
                </b-col>

              </b-row>
              <b-row>

                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col col="6">
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <label> المتوفرة</label>
                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                  <b-row
                    v-for="(item, index) in avialableItem"
                    :key="index"
                    class="d-flex border rounded me-10"
                  >

                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="6"
                      lg="6"
                    >
                      <label>المادة</label>
                      <v-select
                        v-model="item.item_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ItemData"
                        disabled
                        label="name"
                        :clearable="false"
                        :reduce="(val) => val.id"
                        class="mb-2 item-selector-title"
                        placeholder="Select Item"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <label>الكمية</label>
                      <b-form-input
                        v-model="item.quantity"
                        type="number"
                        class="mb-2"
                        disabled
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-group
                        label-for="notes"
                      >
                        <label>الواحدة</label>
                        <v-select
                          v-model="item.unit_id"
                          :dir="
                            $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                          "
                          disabled
                          :options="ubitsOption"
                          label="en_name"
                          :clearable="false"
                          :reduce="(val) => val.id"
                          class="mb-2 item-selector-title"
                          placeholder="الواحدة "
                        />

                      </b-form-group>

                    </b-col>
                    <!--
                        <feather-icon
                          icon="CheckIcon"
                          variant="outline-danger"
                          class="mr-50 mb-10"
                        />
                       <br></br> -->
                  </b-row>

                </b-col>
                <b-col
                  col="6"
                  class="me-10"
                >
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <label>غير المتوفرة</label>
                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                  <b-row
                    v-for="(item, index) in noAvialableItem"
                    :key="index"
                    class="d-flex border rounded me-10"
                  >

                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="6"
                    >
                      <label>المادة</label>
                      <v-select
                        v-model="item.item_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ItemData"
                        disabled
                        label="name"
                        :clearable="false"
                        :reduce="(val) => val.id"
                        class="mb-2 item-selector-title"
                        placeholder="Select Item"
                      />
                    </b-col>
                    <b-col
                      cols="3"
                    >
                      <label>الكمية</label>
                      <b-form-input
                        v-model="item.quantity"
                        type="number"
                        class="mb-2"
                        disabled
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >

                      <b-form-group
                        label-for="notes"
                      >
                        <label>الواحدة</label>
                        <v-select
                          v-model="item.unit_id"
                          :dir="
                            $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                          "
                          disabled
                          :options="ubitsOption"
                          label="en_name"
                          :clearable="false"
                          :reduce="(val) => val.id"
                          class="mb-2 item-selector-title"
                          placeholder="الواحدة "
                        />

                      </b-form-group>

                    </b-col>
                    <!--
                        <feather-icon
                          icon="CheckIcon"
                          variant="outline-danger"
                          class="mr-50 mb-10"
                        />
                       <br></br> -->
                  </b-row>

                </b-col>
              </b-row>

            </b-card-body>

            <!-- Invoice Description: Total -->

            <!-- Spacer -->

            <!-- Note -->
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <b-row>
      <b-card-body class="invoice-padding form-item-section">
        <!-- <h4>اخراج المواد المتوفرة</h4>
        <div ref="form" class="repeater-form" :style="{ height: trHeight }">
          <b-row
            v-for="(item, index) in itemsOptions"
            :key="index"
            ref="row"
            class="pb-2"
          >

            <b-col cols="12">

              <div class="d-none d-lg-flex">
                <b-row class="flex-grow-1 px-1">

                  <b-col cols="3">
                    <label>نوع الحركة</label>
                  </b-col>
                  <b-col cols="3">
                    <label>
                      المادة
                    </label>
                  </b-col>
                  <b-col cols="2">
                    <label>
                      الكمية
                    </label>
                  </b-col>
                  <b-col cols="2">
                    <label>
                      الواحدة
                    </label>
                  </b-col>
                </b-row>
                <div class="form-item-action-col" />
              </div>

              <div class="d-flex border rounded">
                <b-row class="flex-grow-1 p-2">

                  <b-col cols="3">
                    <label >نوع الحركة</label>
                    <v-select
                      v-model="item.itemTitle"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="itemsOptions"
                      label="itemTitle"
                      :clearable="false"
                      class="mb-2 item-selector-title"
                      placeholder="Select Item"
                      @input="(val) => updateItemForm(index, val)"
                    />
                  </b-col>
                  <b-col cols="3">
                    <label class="d-inline d-lg-none">المادة</label>
                    {{ item.itemTitle }}
                  </b-col>
                  <b-col cols="2">
                    <label class="d-inline d-lg-none">الكمية</label>
                    <b-form-input
                      v-model="item.cost"
                      type="number"
                      class="mb-2"
                    />
                  </b-col>
                  <b-col cols="2">
                    <label class="d-inline d-lg-none">الواحدة</label>
                    <b-form-input
                      disabled
                      v-model="item.qty"
                      type="number"
                      class="mb-2"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div> -->
        <b-row v-if="role.some( el => el['name'] === 'project_manager' ) && isUpdate">
          <b-col

            class="border-Primary mb-10 m"
            md="3"
            xl="3"
          >
            <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="x-5"
              variant="outline-primary"

              @click="responseOrder(4)"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50 mb-10"
              />
              <span class="align-middle">موافق</span>
            </b-button>
          </b-col>
          <b-col

            class="border-Primary mb-10 m"
            md="3"
            xl="3"
          >
            <b-button

              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="m-10"
              variant="outline-danger"
              @click="responseOrder(3)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50 mb-10"
              />
              <span class="align-middle">   رفض</span>
            </b-button>
          </b-col>

        </b-row>
        <b-row v-if="role.some( el => el['name'] === 'supply_officer' ) && isUpdate">
          <b-col

            class="border-Primary mb-10"
            md="6"
            xl="6"
          >
            <b-button
              v-if="avialableItem.length >0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"

              @click="dispatchItem"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50 mb-10"
              />
              <span class="align-middle"> توزيع المواد المتوفرة</span>
            </b-button>

            <b-button
              v-if=" noAvialableItem.length >0 || Form.type !=0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="m-10 mt-3"
              variant="outline-danger"
              @click="cretatePurches"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50 mb-10"
              />
              <span class="align-middle">   انشاء طلب شراء</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useRouter } from '@core/utils/utils'
import router from '@/router'
import financeStoreModule from './financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      role: '',
    }
  },

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.roles
    // console.log('userData',userDa
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    dispatchItem() {
      const data = {
        distribute: 1,
      }
      this.$http.post(`/api/v1/complete-material-order/${this.$route.params.id}`, data).then(res => {
        this.$router.push(`/apps/create-release-order/${this.$route.params.id}`)
      })
    },
    cretatePurches() {
      const data = {
        distribute: 0,
      }
      this.$http.post(`/api/v1/complete-material-order/${this.$route.params.id}`, data).then(res => {
        this.$router.push(`/apps/create-purches-order/${this.$route.params.id}`)
      })
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup() {
    const { route } = useRouter()
    const { id } = route.value.params
   const roles = ref(null); // Define a ref to store the role value

    const userData1 = JSON.parse(localStorage.getItem('userData'))
    roles.value = userData1.roles;
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })
    const stat = ref("")
    
    const Form = ref({
      notes: '',
      date: '',
      created_by: '',
      code: '',
      order_items: [
        {
          item: '',
          quantity: 1,
          notes: '',
        },
      ],
    })
    const isUpdate = ref(true)
    const avialableItem = ref([])
    const noAvialableItem = ref([])
    
    store.dispatch('app-finance/GetItemRequest', { id }).then(response => {
      // console.log("response", response);
      Form.value = response.data.data
      stat.value = response.data.data.status
    //  console.log("response", stat.value);
 //  console.log( roles.value);
      if((stat.value  == "تم" || stat.value == "مقبول") && (roles.value.some( el => el['name'] === 'project_manager' ))){
        isUpdate.value = false;
    //    console.log("ffff" + isUpdate.value);
            Vue.swal({
            text: " تمت الموافقة على الطلب مسبقاً",
            icon: 'error',

            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
      }
       
      localStorage.setItem('request_by_user', JSON.stringify(response.data.data.request_by_user))
      localStorage.setItem('department', JSON.stringify(Form.value.department))
    })

    const invoiceData = ref(null)
    const paymentDetails = ref({})

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }
    const warehouse_id = ref('')
    const getAvialableItem = () => {
      const department = Form.value.department.id
      const data = {
        order_items: Form.value.order_items,
      }
      const WarehouseId = warehouse_id.value
      store.dispatch('app-finance/getAvialableItem', { department, WarehouseId, data }).then(response => {
        avialableItem.value = response.avaliable
        noAvialableItem.value = response.not_avaliable

        localStorage.setItem('warehouse_id', JSON.stringify(warehouse_id.value))
        localStorage.setItem('avialableItem', JSON.stringify(avialableItem.value))
        localStorage.setItem('noAvialableItem', JSON.stringify(noAvialableItem.value))
      })
    }

    const responseOrder = status => {
      store
        .dispatch('app-finance/sendResponse', {
          status,
          id,

        })
        .then(response => {
          router.push('/apps/requestItemList')
          Vue.swal({
            title: `${response.data.message}`,
            icon: 'success',

            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          Vue.swal({
            text: `${error.response.data.message}`,
            icon: 'error',

            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }
    const ItemData = ref([])
    const warehouseOption = ref([])
    store.dispatch('app-finance/GetItem').then(response => {
      // console.log(response);
      ItemData.value = response
    })

    store.dispatch('app-finance/GetWareHouse').then(response => {
    //  console.log(response)
      response.forEach(el => {
        warehouseOption.value.push({
          id: el.id,
          name: el.name,
        })
      })
    })
    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },

    ]
    const ubitsOption = ref([])
    store.dispatch('app-finance/GetUnits').then(response => {
      // console.log('ubitsOption,', ubitsOption)
      ubitsOption.value = response
    })
    return {
      isUpdate,
      ubitsOption,
      responseOrder,
      getAvialableItem,
      warehouse_id,
      avialableItem,
      noAvialableItem,
      warehouseOption,
      itemsOptions,
      ItemData,
      Form,
      userData1,
roles,
      updateItemForm,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.border-Primary.mb-10.col-md-12.col-xl-12 {
    text-align: center;
}
button.btn.btn-outline-danger {
    margin-right: 33px !important;
    margin-right: 27px;

}
button.btn.btn-outline-primary {
    margin-left: 32px;
}
.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
